import React, {useState, useMemo} from 'react'
import Flickity from 'react-flickity-component'
import formatCurrency from '../../../helpers/formatCurrency'
import productPricePreview from './utils/productPricePreview';
import {Icon, Spinner} from '@shopify/polaris';
import {IqMajor} from '@shopify/polaris-icons';
import 'flickity/css/flickity.css';
import './cartPagePreview.css';

function CartPagePreview(props) {
  const {
    introText,
    banner,
    offerImageBorderRadius,
    offerImageBorder,
    offerImageBorderColor,
    offerBackgroundColor,
    layout,
    productNumber,
    productsToShow = 2,
    productsPerRow,
    ctaTextColor = 'black',
    product,
    cta,
    ctaBackgroundColor,
    ctaButtonTextColor,
    ctaButtonBorderColor,
    buttonBorderRadius,
    buttonBorderWidth,
    shopBrain,
    featuredImage,
    locale,
    currency,
    setVariantID,
    type,
    compareAtPrice,
  } = props
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState(null);

  const selectedProducts = (shopBrain && productNumber) ? Array(productNumber).fill({}) : product;

  let carouselSelectedVariant;
  if (selection) {
    carouselSelectedVariant = selection?.variants.find(variant => variant.title === selection.title);
  }

  const handleDropDownChange = (variants, event) => {
    setSelection({title: event.target.value, variants: variants});
    setVariantID(event.target.value);
  }

  const addToCartHandle = () => {
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
  };

  let flickityOptions = {
    wrapAround: true,
    pageDots: false,
    groupCells: productsToShow,
    percentPosition: true
  };

  const renderProduct = (productItem, index = null) => {
    const productImage = productItem?.image
      || productItem?.product?.image
      || (productItem?.product?.images && productItem?.product?.images[0]?.originalSrc)
      || 'https://via.placeholder.com/200';

    const isShowDiscountPrice =
      (productItem?.compareAtPrice && productItem.compareAtPrice?.sym && productItem.compareAtPrice?.value !== "")
      || (shopBrain && compareAtPrice?.value !== "");

    const variantPrice = shopBrain
      ? 200
      : (productItem?.variants && productItem?.variants.length)
        ? productItem?.variants[0]?.price
        : 0;

    const productDiscount = !shopBrain ? productItem?.compareAtPrice : compareAtPrice;

    return (
      <div key={index} className='Offer-item'
           style={{fontSize: `18px`, width: layout === 'carousel' && '200px'}}>

        <div>
          <div className='Offer-image__wrapper' style={{height: `160px`}}>
            {
              shopBrain
                ?
                  <div
                    className='Offer-image__icon'
                    style={{
                      border: `${offerImageBorder}px ${offerImageBorderColor} solid`,
                      borderRadius: offerImageBorderRadius,
                      maxWidth: `160px`,
                      maxHeight: `160px`,
                      padding: '10px',
                    }}
                  >
                    <Icon source={IqMajor} color="base" />
                  </div>
                :
                  <img
                    style={{
                      border: `${offerImageBorder}px ${offerImageBorderColor} solid`,
                      borderRadius: offerImageBorderRadius,
                      maxWidth: `160px`,
                      maxHeight: `160px`,
                      padding: '10px',
                    }}
                    src={productImage}
                    alt=""
                  />
            }
          </div>
          <button
            className='Cart-preview__button'
            onClick={addToCartHandle}
            style={{
              backgroundColor: ctaBackgroundColor,
              color: ctaButtonTextColor,
              border: `${buttonBorderWidth}px ${ctaButtonBorderColor} solid`,
              borderRadius: buttonBorderRadius,
            }}
          >
            {
              isLoading
                ? <Spinner accessibilityLabel="Loading Tags" size="small"/>
                : <span style={{fontSize: `16px`}}>{cta ? cta : 'Add to cart'}</span>
            }
          </button>
          <div style={{textAlign: 'left', color: ctaTextColor}}>
            <h1 className='Title'>
              {shopBrain ? 'Product title' : productItem?.title || productItem?.product?.title}
            </h1>
            <h2>
              {!shopBrain && productItem?.description}
            </h2>
          </div>

          {
            !shopBrain && productItem.variants && productItem.variants.length > 1 ?
              <div className="Cart-preview__selection-wrapper">
                <select
                  value={selection ? carouselSelectedVariant.title : productItem.variants[0].title}
                  onChange={(event) => handleDropDownChange(productItem.variants, event)}
                  className="upsellplus-selection"
                  style={{backgroundColor: 'inherit'}}
                >
                  {productItem.variants.map((variant, index) =>
                    <option value={variant.title} key={index}>{variant.title}</option>
                  )}
                </select>
              </div>
              :
              false
          }
        </div>

        <div className='Offer-item__price-container'>
          {
            isShowDiscountPrice &&
            <p className={"Offer-item__price Offer-item__original-price"}>
              {formatCurrency(variantPrice, locale, currency)}
            </p>
          }
          <p className="Offer-item__price">
            {productPricePreview(productDiscount, variantPrice, locale, currency)}
          </p>
        </div>
      </div>
    )
  };

  const previewLeftMargin = productsPerRow > 2 ? ((productsPerRow - 2)/4) * 100 : 0;

  const gridColumnsRepeat = useMemo(() => {
    return !shopBrain
      ? Array.isArray(product)
        ? (productsPerRow > product.length)
          ? productNumber
          : productsPerRow
        : 1
      : (productsPerRow > productNumber)
        ? productNumber
        : productsPerRow
  }, [shopBrain, product, productsPerRow, productNumber]);

  return (
    <div className='Cart-preview__wrapper'>
      <div className='Cart-preview__Price-container'>
        <div className='Price-container__content'>
          <div className='Price-container__price'>
              <span>
                Subtotal
              </span>
            <span>
                $79.99
              </span>
          </div>
          <button className='Cart-preview__button' style={{maxHeight: '34px'}}>
            Checkout
          </button>
        </div>

        <div className='Cart-preview__Offer-cart'>
          <h1 className='Title'>
            {banner}
          </h1>
          <h2>
            {introText}
          </h2>
        </div>
      </div>

      {/*<div className='Cart-preview__Offer-cart'>*/}
      {/*  <h1 className='Title'>*/}
      {/*    {banner}*/}
      {/*  </h1>*/}
      {/*  <h2>*/}
      {/*    {introText}*/}
      {/*  </h2>*/}
      {/*</div>*/}

      <div style={{width: 'max-content', backgroundColor: offerBackgroundColor, position: 'relative', left: `${previewLeftMargin}%`, marginTop: '155px'}}>
        {
          layout === 'carousel'
            ?
            <Flickity options={flickityOptions} elementType='div' key={productsPerRow + productNumber}>
              {
                !Array.isArray(selectedProducts)
                  ? Array(renderProduct(props))
                  : selectedProducts.map((productItem, index) => renderProduct(productItem, index))
              }
            </Flickity>
            :
            <div
              className='Offer-cart__row'
              // style={{display:'grid', gridTemplateColumns: "Mobile" === type ? `repeat(${productsPerRow}, minmax(0, 1fr))` : `repeat(${productsPerRow}, minmax(0, 1fr))`, gap:'2.1rem', alignItems: 'start'}}
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${gridColumnsRepeat}, minmax(0, 1fr))`,
                gap: '2.1rem',
                alignItems: 'start'
              }}
            >
              {
                !Array.isArray(selectedProducts)
                  ? renderProduct(props)
                  : selectedProducts.map((productItem, index) => renderProduct(productItem, index))
              }
            </div>
        }
      </div>
    </div>
  )
}

export default CartPagePreview;